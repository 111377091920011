<template>
  <div>
    <v-layout column>
      <v-layout wrap align-center>
        <div class="text-subtitle-1 black--text ml-4">Pliki ({{ totalItems }}):</div>
        <v-spacer></v-spacer>
        <SessionSelect
          v-if="activeFilesOption === 'doctor'"
          :value.sync="sessionFilter"
          :predefinedValue="predefinedSessionFilter"
          :patientId="patient.id"
          label="Pokaż pliki dla:"
          :vertical="false"
        ></SessionSelect>
        <v-layout shrink>
          <DefaultButton
            :color="displayGrid ? 'primary' : 'secondary'"
            bgColor="transparent"
            icon
            @click.prevent="changeDisplayMode(true)"
          >
            <v-icon>mdi-grid</v-icon>
          </DefaultButton>
          <DefaultButton
            :color="displayGrid ? 'secondary' : 'primary'"
            bgColor="transparent"
            icon
            @click.prevent="changeDisplayMode(false)"
          >
            <v-icon>mdi-format-list-bulleted</v-icon>
          </DefaultButton>
        </v-layout>
      </v-layout>
      <v-layout v-if="displayGrid" wrap>
        <v-flex xs12 sm6 md4 lg3 v-for="file in files" :key="file.file">
          <PatientDocumentTile
            :readonly="readonly"
            :value="file"
            @delete="showDeleteDialog(file)"
            @open="openFile(file)"
            @edit="editImage(file)"
            @openPdf="openFilePdf(file)"
          ></PatientDocumentTile>
        </v-flex>
      </v-layout>
      <PatientDocumentList
        v-if="!displayGrid"
        :readonly="readonly"
        :value="files"
        @download="(x) => downloadFile(x)"
        @open="(x) => openFile(x)"
        @edit="(x) => editImage(x)"
        @openPdf="(x) => openFilePdf(x)"
        @delete="(x) => showDeleteDialog(x)"
      ></PatientDocumentList>
    </v-layout>
    <ImageViewerDialog
      v-if="showDialog"
      :readonly="readonly"
      :value.sync="showDialog"
      :file="dialogFile"
      @delete="showDeleteDialog(dialogFile)"
      @download="downloadFile(dialogFile)"
    ></ImageViewerDialog>
    <PdfViewerDialog
      v-if="showDialogPdf"
      :readonly="readonly"
      :value.sync="showDialogPdf"
      :file="dialogFile"
    ></PdfViewerDialog>
    <ConfirmDialog
      v-if="showConfirmDialog"
      :value.sync="showConfirmDialog"
      :positiveAction="this.delete"
      title="Usuń plik"
      text="Czy na pewno chcesz usunąć ten plik?"
    ></ConfirmDialog>
    <EditImagePopup
      v-if="showEditImagePopup"
      :showPopup="showEditImagePopup"
      :value="dialogFile"
      @cancel="
        () => {
          this.reset();
          this.showEditImagePopup = false;
        }
      "
      @saveEditedImage="saveEditedImage"
    >
    </EditImagePopup>
  </div>
</template>

<script>
import axios from "axios";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import PatientService from "@/services/patient.service";
import DoctorReloadMixin from "@/mixins/DoctorReloadMixin";
import { mapGetters } from "vuex";

const CancelToken = axios.CancelToken;
let cancel = undefined;

export default {
  mixins: [ProxyCRUDMixin, DoctorReloadMixin],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    activeFilesOption: {
      default: "doctor",
    },
    patient: {

    }
  },
  data() {
    return {
      files: [],
      totalItems: 0,
      showDialog: false,
      showDialogPdf: false,
      showEditImagePopup: false,
      dialogFile: null,
      showConfirmDialog: false,
      deleteFile: null,
      displayGrid: true,
      predefinedSessionFilter: { id: 0, name: "Wszystkie pliki" },
      sessionFilter: { id: 0, name: "Wszystkie pliki" },
    };
  },
  watch: {
    displayGrid(mode) {
      localStorage.patientDocuments_displayGrid = mode;
    },
    sessionFilter() {
      this.fetchData();
    },
    activeFilesOption: {
      handler() {
        this.files = [];
        this.fetchData();
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    PatientDocumentTile: () => import("../components/PatientDocumentTile"),
    ImageViewerDialog: () => import("../views/ImageViewerDialog"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    PatientDocumentList: () => import("../components/PatientDocumentList"),
    SessionSelect: () => import("../components/selects/SessionSelect"),
    PdfViewerDialog: () => import("../components/popups/PdfViewerDialog"),
    EditImagePopup: () => import("@/components/popups/EditImagePopup"),
  },
  methods: {
    reloadData() {
      this.fetchData();
    },
    fetchData() {
      this.beforeRequest();
      PatientService.getDocuments(this.sessionFilter.id)
        .then((x) => {
          this.files = x.results;
          this.totalItems = x.count;
          this.reset();
        })
        .catch((error) => {
          if (error.response && error.response.status == 403) {
            this.totalItems = 0;
            this.files = [];
            this.handleError("Brak dostępu.");
          } else {
            this.handleError("Coś poszło nie tak.");
          }
        });
    },
    showDeleteDialog(file) {
      this.deleteFile = file;
      this.showConfirmDialog = true;
    },
    delete() {
      this.beforeRequest();
      PatientService.removeDocument(this.deleteFile.id)
        .then(() => {
          this.reset();
          this.fetchData();
          this.$emit("deleted", true);
        })
        .catch(() => {
          this.reset();
          this.$emit("deleted", false);
        });
    },
    openFile(file) {
      this.dialogFile = file;
      this.showDialog = true;
    },
    editImage(file) {
      this.dialogFile = file;
      this.beforeRequest();
      PatientService.downloadDocument(
        process.env.VUE_APP_URL_BACKEND + file.file
      ).then((x) => {
        return new Promise((resolve, reject) => {
          let blob = new Blob([x.data]);
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        }).then((base64) => {
          this.dialogFile.image = base64;
          this.showEditImagePopup = true;
        });
      });
    },
    openFilePdf(file) {
      this.dialogFile = file;
      this.showDialogPdf = true;
    },
    changeDisplayMode(mode) {
      this.displayGrid = mode;
    },
    saveEditedImage(fileData) {
      fetch(fileData.image)
        .then((res) => res.blob())
        .then((blob) => {
          let formData = new FormData();
          const newFile = new File([blob], fileData.title);
          formData.append("file", newFile);
          formData.append("title", fileData.title);
          formData.append("size", fileData.size);
          PatientService.updateDocument(
            fileData.id,
            formData,
            this.onUploadProgress,
            new CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              cancel = c;
            })
          )
            .then(() => {
              this.reloadData();
              this.showEditImagePopup = false;
            })
            .catch(() => {
              this.handleError("Coś poszło nie tak.");
              this.showEditImagePopup = false;
            });
        });
    },
    downloadFile(file) {
      this.beforeRequest();
      PatientService.downloadDocument(
        process.env.VUE_APP_URL_BACKEND + file.file
      )
        .then((x) => {
          this.reset();
          const url = window.URL.createObjectURL(new Blob([x.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", file.title); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.handleError("Coś poszło nie tak.");
        });
    },
  },
  mounted() {
    this.fetchData();
  },
  created() {
    if (localStorage.patientDocuments_displayGrid) {
      this.displayGrid = localStorage.patientDocuments_displayGrid == "true";
    }
  },
};
</script>
